import {  memo, useCallback, useEffect, FC, useRef } from "react";
import {
    Button,
    Center,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Spacer,
    Spinner,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Th,
    Tr,
} from "@chakra-ui/react";

import { useStepFunctions } from "../../../hooks/useStepFunctins";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { TableContent } from "../../organisms/layout/TableContent";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { RepeatIcon } from "@chakra-ui/icons";
import { SfnExecutionRow } from "../../organisms/datarow/SfnExecutionRow";
import { useParameters } from "../../../hooks/useParameters";
import appconfig from "../../../config.json";
import { TenantSingleParm } from "../../../types/api/parameterTypes";

export const TenantOperations: FC = memo(() => {
    const { loginUser, selectTenant } = useLoginUser();
    const { getParameter,parameter, loading } = useParameters();
    const { startExecution, getListExecutions, loading: sfnExecutionListLoading, sfnExecutions } = useStepFunctions();

    const didLogRef = useRef(false);
    useEffect(() => {
        if (didLogRef.current === false) {
            didLogRef.current = true;
            let tenant_id: string = "";
            if (selectTenant) {
                tenant_id = selectTenant;
            } else {
                if (loginUser) tenant_id = loginUser.tenant;
            }
            const basePath = "/" +
            appconfig.IOT_MANAGEMENT_ENV +
            "/" +
            tenant_id +
            "/";
        
            getParameter(basePath + TenantSingleParm.DevicesBuildArn);
        }
    }, []);

    useEffect(() => {
        if (parameter){
            getListExecutions(parameter);
        }
    }, [parameter]);

    const onClickStnStart = useCallback(() => {
        if (parameter) {
            if (window.confirm(`設定反映の処理を実行しますか？（元には戻せません。）`)) {
                startExecution(parameter);
            }
        }
    }, [parameter,startExecution]);

    // 更新ボタン（StepFunctionsの処理状態を取得しなおす）
    const onClickReload = useCallback(() => {
        if (parameter){
            getListExecutions(parameter);
        }
    }, [parameter,getListExecutions]);

    return (
        <Stack maxW={"1200px"}>
            <PageDiscription>
                センサー管理の設定反映処理を状況です。
            </PageDiscription>
            <Tabs>
                <TabList>
                    <Tab>{parameter}</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <HStack my="2" h="10" alignItems={"flex-end"}>
                            <FormLabel>処理実行履歴（最新10件）</FormLabel>
                            <Spacer />
                            <Flex pr="5">
                                <Button
                                    colorScheme="red"
                                    variant="solid"
                                    onClick={onClickStnStart}
                                    size={{ base: "sm", md: "md" }}
                                    >
                                    {"処理実行"}
                                </Button>
                            </Flex>
                            <Flex pr="3">
                                <Button
                                    leftIcon={<RepeatIcon />}
                                    colorScheme="teal"
                                    variant="solid"
                                    onClick={onClickReload}
                                    size={{ base: "sm", md: "md" }}
                                >
                                    {"一覧更新"}
                                </Button>
                            </Flex>
                        </HStack>
                        {sfnExecutionListLoading ? (
                            <Center h="50vh">
                                <Spinner color="teal.200" />
                            </Center>
                        ) : (
                            <>
                                <TableContent
                                    heightOffset="-100px"
                                    headerRows={
                                        <Tr key="header">
                                            <Th>処理開始日時</Th>
                                            <Th>処理完了日時</Th>
                                            <Th>状態</Th>
                                            <Th w="100%">実行No</Th>
                                        </Tr>
                                    }
                                    bodyRows={sfnExecutions.map((obj) => (
                                        <SfnExecutionRow
                                            key={obj.executionArn}
                                            ExecutionArn={obj.executionArn}
                                            Name={obj.name}
                                            StartDate={obj.startDate}
                                            Status={obj.status}
                                            StopDate={obj.stopDate}
                                        />
                                    ))}
                                ></TableContent>
                            </>
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </Stack>
    );
});
