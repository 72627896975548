import { memo, useCallback, FC } from "react";
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  HStack,
  FormControl,
  Heading,
  Input,
  Link,
  Stack,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import appconfig from "../../../config.json";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { useRegister } from "../../../hooks/useRegister";
import { TierPtn} from "../../../types/api/commonTypes";
import { Controller, useForm } from "react-hook-form";
import { TenantInfo } from "../../../types/api/tenantTableTypes";

export const Register: FC = memo(() => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<TenantInfo>({
    defaultValues: {
      tenant_id: "",
      tier: "nopersonal",
    },
  });
  const { reg } = useRegister();
  const ver2:boolean = appconfig.IOT_MANAGEMENT_ENV.startsWith("2")
  const navigate = useNavigate();

  const handleOnSubmit = (data: TenantInfo) => {
    reg(
      data.tenant_id,
      data.accountName,
      data.companyName,
      data.email,
      data.tenantNamespace,
      data.tier,
      data.binaryStorageCapacityLimit || 0
    );
  };
  const handleOnError = (errors: any) => console.log(errors);
  const onClickCancel = useCallback(() => navigate("/login"), []);

  return (
    <Flex alignItems="center" justifyContent="center">
      <Box bg="white" w="sm" p={4} borderRadius="md" shadow="md">
        <Heading as="h1" size="md" textAlign="center">
          テナント・追加
        </Heading>
        <Divider my={4} />
        <Stack spacing={6} py={4} px={10}>
          <form>
            <FormControl isInvalid={Boolean(errors.tenant_id)}>
              <FormLabel>テナントコード(市町村)</FormLabel>
              <Input
                id="tenant_id"
                placeholder="ID"
                {...register("tenant_id", {
                  required: "必須入力です。",
                  pattern: {
                    value: /^[a-z0-9]+$/,
                    message: "英(小文字)数字のみ使用できます。",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.tenant_id && errors.tenant_id.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.accountName)}>
              <FormLabel>表示名</FormLabel>
              <Input
                id="accountName"
                placeholder="〇〇市"
                {...register("accountName", {
                  required: "必須入力です。",
                })}
              />
              <FormErrorMessage>
                {errors.accountName && errors.accountName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>会社名</FormLabel>
              <Input
                id="companyName"
                placeholder="companyName"
                {...register("companyName")}
              />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.email)}>
              <FormLabel>メールアドレス</FormLabel>
              <Input
                id="email"
                placeholder="email"
                {...register("email", {
                  required: false,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "正しいメールアドレスの形式で入力してください。",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.tenantNamespace)}>
              <FormLabel>ID設定</FormLabel>
              <Input
                id="tenantNamespace"
                placeholder="jp.xxxx.zzzz"
                {...register("tenantNamespace", {
                  required: "必須入力です。",
                  pattern: {
                    value: /^[A-Z0-9.]+$/i,
                    message: "ピリオド区切りの形式で入力してください。",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.tenantNamespace && errors.tenantNamespace.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.tier)}>
              <FormLabel>Plan</FormLabel>
              <Select id="tier" placeholder="plan" {...register("tier")}>
                <option value={TierPtn.no_personal}>Standard Tier</option>
                {ver2?(<option value={TierPtn.v2}>Professional Tier</option>):(<></>)}
              </Select>
              <FormErrorMessage>
                {errors.tier && errors.tier.message}
              </FormErrorMessage>
            </FormControl>
            {ver2?(<FormControl isDisabled={false}>
              <FormLabel>バイナリ：ストレージ制限[GB]</FormLabel>
              <Controller
                name="binaryStorageCapacityLimit"
                control={control}
                render={({ field: { ref, ...restField } }) => (
                  <NumberInput
                    {...restField}
                    precision={0}
                    step={1}
                    min={0}
                    max={1000}
                  >
                    <NumberInputField name={restField.name} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>):(<></>)}
          </form>
          <HStack>
            <PrimaryButton
              onClick={handleSubmit(handleOnSubmit, handleOnError)}
            >
              登録
            </PrimaryButton>
            <Link onClick={onClickCancel}>Cancel</Link>
          </HStack>
        </Stack>
      </Box>
    </Flex>
  );
});
