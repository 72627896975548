import { memo, useCallback, useEffect, useState, FC } from "react";
import {
  Center,
  HStack,
  Spinner,
  Stack,
  Th,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import { useUser } from "../../../hooks/useUser";
import { UserDetailModal } from "../../organisms/modal/UserDetailModal";
import { useSelectUser } from "../../../hooks/useSelectUser";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { AddButton } from "../../atoms/button/AddButton";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { SearchInput } from "../../atoms/Input/SearchInput";
import { TableContent } from "../../organisms/layout/TableContent";
import { UserRow } from "../../organisms/datarow/UserRow";

export const Users: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    getUsers,
    userAdd,
    userEdit,
    userDelete,
    userEnable,
    userDisable,
    userReset,
    loading,
    users,
  } = useUser(onClose);
  const { onSelectUser, selectedUser } = useSelectUser();
  const { loginUser } = useLoginUser();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => getUsers(), [getUsers]);

  const onClickUser = useCallback(
    (userName: string) => {
      onSelectUser({ userName, users, onOpen });
    },
    [users, onSelectUser, onOpen]
  );

  return (
    <Stack maxW={"1200px"}>
      <PageDiscription>ユーザーを選択してください。</PageDiscription>
      <HStack>
        <SearchInput
          placeholder={"ユーザーID または ユーザー氏名"}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <AddButton onClick={() => onClickUser("")} />
      </HStack>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.200" />
        </Center>
      ) : (
        <>
          <TableContent
            headerRows={
              <Tr key="header">
                <Th>ユーザー氏名・ユーザーID</Th>
                <Th>ロール</Th>
                <Th>状態</Th>
              </Tr>
            }
            bodyRows={users
              .filter((obj) => {
                return (
                  searchValue === "" ||
                  obj.userName.indexOf(searchValue) >= 0 ||
                  `${obj.lastName} ${obj.firstName}`.indexOf(searchValue) >= 0
                );
              })
              .map((obj) => (
                <UserRow
                  key={obj.userName}
                  userName={obj.userName}
                  firstName={obj.firstName}
                  lastName={obj.lastName}
                  enabled={obj.enabled}
                  confirmedStatus={obj.confirmedStatus}
                  role={obj.role}
                  onClick={onClickUser}
                />
              ))}
          ></TableContent>
          {/* <Wrap px={{ base: 4, md: 10 }} py={{ base: 1, md: 2 }}>
            {users
              .filter((obj) => {
                return (
                  searchValue === "" || obj.userName.indexOf(searchValue) >= 0
                );
              })
              .map((obj) => (
                <WrapItem key={obj.userName} mx="auto">
                  <UserCard
                    userName={obj.userName}
                    firstName={obj.firstName}
                    lastName={obj.lastName}
                    confirmedStatus={obj.confirmedStatus}
                    enabled={obj.enabled}
                    role={obj.role}
                    onClick={onClickUser}
                  />
                </WrapItem>
              ))}
          </Wrap> */}
        </>
      )}
      <UserDetailModal
        isOpen={isOpen}
        isAdmin={loginUser?.isAdmin}
        onInsert={userAdd}
        onUpdate={userEdit}
        onDelete={userDelete}
        onEnable={userEnable}
        onDisable={userDisable}
        onReset={userReset}
        onClose={onClose}
        user={selectedUser}
      />
    </Stack>
  );
});
