const securityLevel_V1 = {
  None: "",
  Public: "public",
  Private: "private",
  Anonymized: "a-personal", // 匿名加工情報
} as const;
export type SecurityLevel_V1Type =
  (typeof securityLevel_V1)[keyof typeof securityLevel_V1];

const securityLevel_personal = {
  Related: "related", // 個人関連情報
  Pseudonymized: "pseudonymized", // 仮名加工情報
} as const;

export const SecurityLevelPtn = {
  ...securityLevel_V1,
  ...securityLevel_personal,
} as const;
export type SecurityLevelType =
  (typeof SecurityLevelPtn)[keyof typeof SecurityLevelPtn];

export const PermissionPtn = {
  read_only: "read_only",
  read_write: "read_write",
  non: "",
};

export type PermissionType = (typeof PermissionPtn)[keyof typeof PermissionPtn];

export const TierPtn = {
  v2: "high_security",
  no_personal: "no_personal",
  non: "",
};
export type TierType = (typeof TierPtn)[keyof typeof TierPtn];

export const StreamInputPtn = {
  StreamlineCityDeviceNestedStack: "StreamlineCityDeviceNestedStack",
  StreamlineCityDeviceRestApiNestedStack:
    "StreamlineCityDeviceRestApiNestedStack",
  StreamlineCityDeviceRestApiOnlyNestedStack:
    "StreamlineCityDeviceRestApiOnlyNestedStack",
  StreamlineCityDeviceprivateApiNestedStack:
    "StreamlineCityDeviceprivateApiNestedStack",
  StreamlineEltresDeviceEltresNestedStack:
    "StreamlineEltresDeviceEltresNestedStack",
  StreamlineCityDeviceEltresNestedStack:
    "StreamlineCityDeviceEltresNestedStack",
  StreamlineCityDeviceMqttNestedStack: "StreamlineCityDeviceMqttNestedStack",
  StreamlineRawdataRestApiNestedStack: "StreamlineRawdataRestApiNestedStack",
  StreamlineCityDeviceRestApiCsvNestedStack:
    "StreamlineCityDeviceRestApiCsvNestedStack",
  StreamlineCityDeviceLocalApiNestedStack:
    "StreamlineCityDeviceLocalApiNestedStack",
  StaticCityDataCsv: "StaticCityDataCsv",
  StreamlineCityTopicMqttNestedStack: "StreamlineCityTopicMqttNestedStack",
  realtimeStack: "realtimeStack", // 未実装
};

export type StreamInputype =
  (typeof StreamInputPtn)[keyof typeof StreamInputPtn];

