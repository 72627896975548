import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  HStack,
  Textarea,
  Tabs,
  TabPanels,
  TabPanel,
  Spacer,
  Badge,
  Tab,
  TabList,
  Select,
  Flex,
  Text,
} from "@chakra-ui/react";

import { MailTemplate } from "../../../types/api/mailTemplate";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { CKEditor } from "ckeditor4-react";

type Props = {
  mailTemplate: MailTemplate;
  isOpen: boolean;
  onSendMail: (
    FromEmailAddress: string,
    ToEmailAddress: string,
    TemplateName: string,
    TemplateData: { [name: string]: string }
  ) => void;
  onClose: () => void;
};

export const SendTemplateModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, onSendMail, mailTemplate } = props;

  const [templateName, setTemplateName] = useState("");
  const [subjectPart, setSubjectPart] = useState("");
  const [textPart, setTextPart] = useState("");
  const [htmlPart, setHtmlPart] = useState("");

  const [FromEmailAddress, setFromEmailAddress] = useState("");
  const [ToEmailAddress, setToEmailAddress] = useState("");

  const dataList = [
    "data1",
    "data2",
    "data3",
    "data4",
    "data5",
    "data6",
    "data7",
    "data8",
    "data9",
    "result",
  ];
  const [TemplateData, setTemplateData] = useState<{ [name: string]: string }>({
    data1: "",
    data2: "",
    data3: "",
    data4: "",
    data5: "",
    data6: "",
    data7: "",
    data8: "",
    data9: "",
    result: "TRUE",
  });

  const onChangeData = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    let newData = { ...TemplateData };
    newData[key] = e.target.value;
    setTemplateData(newData);
  };
  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>, key: string) => {
    let newData = { ...TemplateData };
    newData[key] = e.target.value;
    setTemplateData(newData);
  };

  const onChangeFromEmailAddress = (e: ChangeEvent<HTMLInputElement>) =>
    setFromEmailAddress(e.target.value);
  const onChangeToEmailAddress = (e: ChangeEvent<HTMLInputElement>) =>
    setToEmailAddress(e.target.value);

  useEffect(() => {
    onModalReset();
  }, [mailTemplate]);

  const onModalReset = () => {
    if (mailTemplate) {
      setTemplateName(mailTemplate.TemplateName ?? "");
      setSubjectPart(mailTemplate.SubjectPart ?? "");
      setTextPart(mailTemplate.TextPart);
      setHtmlPart(mailTemplate.HtmlPart);

      // 編集後に再送信する時のために値は消さない
      // setFromEmailAddress("");
      // setToEmailAddress("");
      // setTemplateData({});
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      trapFocus={false}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>{`「${templateName}」テスト送信`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Tabs>
            <TabPanels>
              <TabPanel>
                <Tabs>
                  <TabList>
                    <Tab>送信設定</Tab>
                    <Tab>テキスト形式</Tab>
                    <Tab>HTML形式</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Stack spacing={4}>
                        <FormControl>
                          <FormLabel>
                            <Flex>
                              送信元アドレス
                              <Badge colorScheme="red" ml={2}>
                                必須
                              </Badge>
                              <Text ml="2">
                                ※検証済の送信元アドレスを指定してください。
                              </Text>
                            </Flex>
                          </FormLabel>
                          <Input
                            type="email"
                            value={FromEmailAddress}
                            onChange={(e) => onChangeFromEmailAddress(e)}
                            placeholder={"email (from)"}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>
                            送信先アドレス
                            <Badge colorScheme="red" ml={2}>
                              必須
                            </Badge>
                          </FormLabel>
                          <Input
                            type="email"
                            value={ToEmailAddress}
                            onChange={(e) => onChangeToEmailAddress(e)}
                            placeholder={"email (to)"}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>データ（引数）</FormLabel>
                          {dataList.map((data) => (
                            <HStack key={data}>
                              <FormLabel>{data}</FormLabel>
                              {data === "result" ? (
                                <Select
                                  value={TemplateData[data]}
                                  onChange={(e) => onChangeSelect(e, data)}
                                >
                                  <option value={"TRUE"}>該当</option>
                                  <option value={""}>非該当</option>
                                </Select>
                              ) : (
                                <Input
                                  onChange={(e) => onChangeData(e, data)}
                                  value={TemplateData[data]}
                                />
                              )}
                            </HStack>
                          ))}
                        </FormControl>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack spacing={4}>
                        <FormControl>
                          <FormLabel>件名</FormLabel>
                          <Input readOnly={true} value={subjectPart} />
                        </FormControl>
                        <FormControl>
                          <FormLabel>本文</FormLabel>
                          <Textarea
                            readOnly={true}
                            value={textPart}
                            rows={12}
                          />
                        </FormControl>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack spacing={4}>
                        <FormControl>
                          <FormLabel>件名</FormLabel>
                          <Input readOnly={true} value={subjectPart} />
                        </FormControl>
                        <FormControl>
                          <FormLabel>本文</FormLabel>
                          <CKEditor
                            readOnly={true}
                            config={{ width: "100%" }}
                            initData={mailTemplate.HtmlPart}
                          />
                        </FormControl>
                      </Stack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <HStack mt={4}>
                  <Spacer />
                  <>
                    <PrimaryButton
                      onClick={() => {
                        onSendMail(
                          FromEmailAddress,
                          ToEmailAddress,
                          templateName,
                          TemplateData
                        );
                      }}
                      disabled={
                        FromEmailAddress === "" || ToEmailAddress === ""
                      }
                    >
                      送信
                    </PrimaryButton>
                  </>
                </HStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
});
