import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
  FC,
  useRef,
} from "react";
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Spacer,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Th,
  Tr,
} from "@chakra-ui/react";

import { useStepFunctions } from "../../../hooks/useStepFunctins";
import { useOrionTypes } from "../../../hooks/useOrionTypes";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { TableContent } from "../../organisms/layout/TableContent";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { RepeatIcon } from "@chakra-ui/icons";
import { SfnExecutionRow } from "../../organisms/datarow/SfnExecutionRow";

export const OrionEntityOperations: FC = memo(() => {
  const { loginUser, selectTenant } = useLoginUser();

  const {
    runTruncate,
    getListTruncats,
    loading: sfnExecutionListLoading,
    sfnExecutions,
  } = useStepFunctions();
  const { getOrionTypes, loading, orionTypes } = useOrionTypes();

  const [selectType, setSelectType] = useState<string | undefined>(undefined);

  const didLogRef = useRef(false);
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      let tenant_id: string = "";
      if (selectTenant) {
        tenant_id = selectTenant;
      } else {
        if (loginUser) tenant_id = loginUser.tenant;
      }
      getOrionTypes(tenant_id);
    }
  }, []);

  // deviceを選択した際の処理
  const onChangeEntityType = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectType(e.target.value);
  };

  useEffect(() => {
    getListTruncats();
  }, []);

  const onClickTruncateRun = useCallback(() => {
    if (selectType) {
      if (
        window.confirm(
          `FIWARE(Orion)のデータ削除を実行しますか？（元には戻せません。）`
        )
      ) {
        runTruncate(selectType);
      }
    }
  }, [selectType]);

  // 更新ボタン（ファイル一覧とworkflowの処理状態を取得しなおす）
  const onClickReload = useCallback(() => {
    getListTruncats();
  }, [getListTruncats]);

  return (
    <Stack maxW={"1200px"}>
      <PageDiscription>
        エンティティ・タイプを選択し、各種データ処理を実行してください。
      </PageDiscription>
      <FormControl>
        <HStack>
          <FormLabel whiteSpace={"nowrap"}>
            エンティティ・タイプの種類
          </FormLabel>
          {loading ? (
            <Spinner key="deviceSpinner" color="teal.200" />
          ) : (
            <Select
              value={selectType}
              onChange={onChangeEntityType}
              bg="gray.50"
              disabled={loading}
            >
              <option value={""}></option>
              {orionTypes?.map((entityType) => (
                <option key={entityType.type} value={entityType.type}>
                  {entityType.type}
                </option>
              ))}
            </Select>
          )}
        </HStack>
      </FormControl>
      <Tabs>
        <TabList>
          <Tab>データ削除処理</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack my="2" h="10" alignItems={"flex-end"}>
              <FormLabel>処理実行履歴（最新10件）</FormLabel>
              <Spacer />
              <Flex pr="5">
                <Button
                  colorScheme="red"
                  variant="solid"
                  onClick={onClickTruncateRun}
                  size={{ base: "sm", md: "md" }}
                >
                  {"削除処理実行"}
                </Button>
              </Flex>
              <Flex pr="3">
                <Button
                  leftIcon={<RepeatIcon />}
                  colorScheme="teal"
                  variant="solid"
                  onClick={onClickReload}
                  size={{ base: "sm", md: "md" }}
                >
                  {"更新"}
                </Button>
              </Flex>
            </HStack>
            {sfnExecutionListLoading ? (
              <Center h="50vh">
                <Spinner color="teal.200" />
              </Center>
            ) : (
              <>
                <TableContent
                  heightOffset="-100px"
                  headerRows={
                    <Tr key="header">
                      <Th>処理開始日時</Th>
                      <Th>処理完了日時</Th>
                      <Th>状態</Th>
                      <Th w="100%">実行No</Th>
                    </Tr>
                  }
                  bodyRows={sfnExecutions.map((obj) => (
                    <SfnExecutionRow
                      key={obj.executionArn}
                      ExecutionArn={obj.executionArn}
                      Name={obj.name}
                      StartDate={obj.startDate}
                      Status={obj.status}
                      StopDate={obj.stopDate}
                    />
                  ))}
                ></TableContent>
              </>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
});
