import { memo, useCallback, useEffect, FC } from "react";
import {
  Center,
  Spinner,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { TenantCard } from "../../organisms/tenant/TenantCard";
import { useAllTenants } from "../../../hooks/useAllTenants";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { useMessage } from "../../../hooks/useMessage";

export const TenantSelecter: FC = memo(() => {
  const { getTenants, loading, tenants } = useAllTenants();
  const { loginUser, setSelectTenant } = useLoginUser();
  const { showMessage } = useMessage();

  useEffect(() => getTenants(), [getTenants]);

  const onClickTenant = useCallback(
    (tenantId: string) => {
      setSelectTenant(tenantId);
      showMessage({
        title: `${tenantId}に切り替えました。`,
        status: "success",
      });
    },
    [tenants]
  );

  return (
    <>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.200" />
        </Center>
      ) : (
        <Wrap p={{ base: 4, md: 10 }}>
          {tenants.map((obj) => (
            <WrapItem key={obj.tenant_id} mx="auto">
              <TenantCard
                tenant_id={obj.tenant_id}
                accountName={obj.accountName}
                onClick={onClickTenant}
              />
            </WrapItem>
          ))}
        </Wrap>
      )}
    </>
  );
});
