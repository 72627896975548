import { memo, useCallback, useState, useEffect, useRef } from "react";
import { useOrionSubscriptions } from "../../../hooks/useOrionSubscriptions";
import { OrionSubscriptionModal } from "../../organisms/modal/OrionSubscriptionModal";
import { Center, Spinner, Stack, Th, Tr, useDisclosure } from "@chakra-ui/react";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { OrionSubscriptionRow } from "../../organisms/datarow/OrionSubscriptionRow";
import { TableContent } from "../../organisms/layout/TableContent";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { OrionSubscription } from "../../../types/api/orionSubscription";
export const OrionSubscriptions = memo(() => {
  const dlSubscription = useDisclosure();
  const {
    getOrionSubscriptions,
    loading,
    orionSubscriptions,
  } = useOrionSubscriptions();
  const [selectedSubscription, setSelectedSubscription] =
    useState<OrionSubscription>();
  const didLogRef = useRef(false);
  useEffect(() => {
    if (didLogRef.current === false) {
      didLogRef.current = true;
      getOrionSubscriptions();
    }
  }, []);

  const onClickSubscription = useCallback(
    (id: string) => {
      const targetSubscription= orionSubscriptions.find(
        (obj) => obj.id === id
      );
      if (targetSubscription) {
        setSelectedSubscription(targetSubscription);
        dlSubscription.onOpen();
      }
    },
    [orionSubscriptions]
  );
  return (
    <Stack>
      <PageDiscription>Subscriptionを選択してください。</PageDiscription>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.200" />
        </Center>
      ) : (
        <>
          <TableContent
            heightOffset="50px"
            headerRows={
              <Tr key="header">
                <Th width="30%">id</Th>
                <Th width="50%">description</Th>
                <Th width="20%">status</Th>
              </Tr>
            }
            bodyRows={orionSubscriptions.map((obj) => (
              <OrionSubscriptionRow
                key={obj.id}
                id={obj.id}
                description={obj.description}
                expires={obj.expires}
                status={obj.status}
                onClick={onClickSubscription}
              />
            ))}
          ></TableContent>
        </>
      )}
          <OrionSubscriptionModal
        isOpen={dlSubscription.isOpen}
        onClose={dlSubscription.onClose}
        Subscription={selectedSubscription}
      />
</Stack>

  );
});
