import { useCallback, useState } from "react";
import axios from "axios";

import { TenantInfo } from "../types/api/tenantTableTypes";
import { useMessage } from "./useMessage";
import { useLoginUser } from "../hooks/useLoginUser";
import appconfig from "../config.json";

export const useTenant = () => {
  const { showMessage } = useMessage();
  const { loginUser,tokenRefresh } = useLoginUser();
  const [loading, setLoading] = useState(false);
  const [tenant, setTenant] = useState<TenantInfo>();

  const TENANT_MANAGER_URL: string = appconfig["IOT_MANAGEMENT_API_URL"];

  type IPostResponse = {
    access?: string;
    token?: string;
    newPasswordRequired?: string;
  };

  const tenantEdit = useCallback(
    (
      tenant:TenantInfo
    ) => {
      setLoading(true);
      new Promise((resolve)=>resolve(tokenRefresh()))
      .then((token)=>{
        axios
          .put<any>(TENANT_MANAGER_URL + "/tenant", tenant, {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(async (res) => {
            if (res.data) {
              console.log(res.data);
              showMessage({ title: "更新しました。", status: "success" });
              setLoading(false);
            } else {
              showMessage({ title: res.statusText, status: "error" });
              setLoading(false);
            }
          })
          .catch(() => {
            showMessage({ title: "テナントを更新できません", status: "error" });
            setLoading(false);
          });
      });
      },
    []
  );
  const tenantDelete = useCallback((tenant_id: string) => {
    setLoading(true);
    new Promise((resolve)=>resolve(tokenRefresh()))
    .then((token)=>{
      axios
        .delete<any>(TENANT_MANAGER_URL + "/tenant/" + tenant_id, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(async (res) => {
          if (res.status == 204) {
            showMessage({ title: "削除しました。", status: "success" });
            setLoading(false);
          } else if (res.status == 403) {
            showMessage({ title: "権限エラー:" + res.data, status: "error" });
            setLoading(false);
          } else {
            showMessage({ title: res.statusText, status: "error" });
            setLoading(false);
          }
        })
        .catch(() => {
          showMessage({ title: "テナントが削除できません", status: "error" });
          setLoading(false);
        });
      });
  }, []);

  const tenantGet = useCallback((tenant_id: string) => {
    setLoading(true);
    new Promise((resolve)=>resolve(tokenRefresh()))
    .then((token)=>{
      axios
        .get<TenantInfo>(TENANT_MANAGER_URL + "/tenant/" + tenant_id, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((res) => setTenant(res.data))
        .catch(() =>
          showMessage({ title: "テナント取得に失敗しました", status: "error" })
        )
        .finally(() => setLoading(false));
      });
  }, []);

  return { tenantGet,tenantEdit, tenantDelete, loading, tenant };
};
