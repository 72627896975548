import { StreamInputype } from "./commonTypes";


export interface EntityTypePtn {
  readonly TypeName: string;
  readonly Subscriptions: SubscriptionInfo[];
  readonly optionApi?: ApiInfo[];
  readonly provider?: ProviderInfo;
  readonly schema?:object;
}

export interface SubscriptionInfo {
  SubscriptionPtn: string;
  description?: string;
  subject: SubjectInfo;
  notification: NotificationInfo;
  throttling?: number;
}

export interface SubjectInfo {
  entities: any[];
  condition: any;
}

export interface NotificationInfo {
  attrs?: string[];
  http?: any;
  attrsFormat?: string;
  exceptAttrs?: string[];
  expression?: any;
  alterationTypes?: string[];
  metadata?: string[];
}

export const ApiPtn = {
  binary: "binary",
  readonly: "readonly",
  Data_in_chronological: "Data_in_chronological",
  no_type: "no_type",
} as const;
export type ApiPtnType = (typeof ApiPtn)[keyof typeof ApiPtn];

export interface ApiInfo {
  ApiPtn: ApiPtnType;
  ContentType?: string;
}

export interface ProviderInfo {
  url: string;
  attrs: string[];
}

export interface DevicePtn {
  DeviceName: string;
  NestStak: StreamInputype;
  ChangFunctionArn?: string;
  ApiKey?: string;
  Topic?: string;
  EntityType?: string;
  ChangJobName?: string;
  ChangJobRoleArn?: string;
  ChangNgsiJobName?: string;
  MonthlyJobName?: string;
  MonthlyJobRoleArn?: string;
  OpenSearchIndexTemplate?: object;
  OpenSearchTimeFieldName?: string;
  OpenSearchPipelineTemplate?: object;
  CsvHeaderExist?: boolean;
  CsvHeaders?: string;
}

export interface EndPointInfo {
  ServiceType: string;
  URL: string;
  tenantName: string;
  SecurityLevel: string;
}

export const EnvSingleParm = {
  SystemAlertTopicArn: "SystemAlertTopic", // システムアラーとの発信用Topic:Arn
  OrionVpclinkId: "orion-vpclink-id", // orion-vpclink-id
  OrionUrl: "orion-url", // orion-url
  QuantumleapVpclinkId: "quantumleap-vpclink-id", // quantumleap-vpclink-id
  QuantumleapUrl: "quantumleap-url", // quantumleap-url
  OrionSubscriptionProviderToken: "orion-subscription-provider-token", // orion-subscription-provider-token
  OrionRegistrationProviderToken: "orion-registration-provider-token", //orion-registration-provider-token
  ProviderUrl: "Provider-url", // Registrations用Provider-url
  ProviderApiId: "Provider-api-id", // Registrations用Provider api-id
  ProviderApiResourceid: "Provider-api-resourceid", // Registrations用Provider api-resourceid
  CognitoDomainName: "cognito-domain-name", // cognito-domain-name
  UserPoolId: "user-pool-id", //user-pool-id
  TenantTableArn: "tenant-table-Arn" , // tenant-table-Arn
  GroupTableArn: "group-table-Arn" , // group-table-Arn
  UserTableArn: "user-table-Arn" , // user-table-Arn
  ParamTableArn: "param-table-Arn" , // 各種パラメータテーブル
  ManagerIdentityPoolId : "identity-PoolId", // IoT-Manager Identity Pool
  ManagerUserPoolClientId : 'IoT-Manager/user-PoolClientId', // IoT-Manager ClientId
  TruncateStateMachineArn : 'truncate-state-machine-Arn', // Orion Entity Clear StepFunction
  PublicReceiveApi :'public-recive-api', // 受信用APIのID
  PublicReceiveApiRoot :'public-recive-api-root', // 受信用APIのroot
  ReceiveDeveloperUsagePlanid :'recieve-developer-usage-planid', // 受信用APIのデベロッパーポータルユーザ用の使用量プラン
  OpensearchAccountAdminRoleArn: 'opensearch-accountAdminRole-arn',
  OpensearchDomainArn: 'opensearch-domain-arn' ,
  OpensearchDomainEndpoint: 'opensearch-domain-endpoint',
  OpensearchFirehoseRoleArn: 'opensearch-firehose-role-arn',
  OpensearchIdentityPoolId: 'opensearch-identityPool-id' ,
  OpensearchLambdaRoleArn: 'opensearch-lambda-role-arn' ,
  Ckan210DomainEndpoint: 'ckan210-domain-endpoint' ,
  Ckan210ApiKey: 'ckan210/apiKey' ,
  Ckan210ResorceProvider: 'ckan210-provider-service-token' ,
  Ckan29DomainEndpoint: 'ckan-domain-endpoint' ,
  Ckan29ApiKey: 'ckan/apiKey' ,
};

export const TenantSingleParm = {
  DevicesBuildArn: "devices-build-arn", // テナント単位のIoTDeviceCDKを実行するstateMachineArn
  UpdateQueueArn: "update-queue-arn", // テナント単位のOrionへのNgsiV2更新を実行するSQSのarn
  AuthorizerFunctionArn: "AuthorizerFunction-arn", // テナント単位のAPI権限チェック用オーサライザー
  PublicDataLake: "public-data-lake", // テナント単位のpublic Bucket Name
  PrivateDataLake: "private-data-lake", // テナント単位のprivate Bucket Name
  AnonymizedPersonalDataLake: "a-personal-data-lake", // テナント単位の匿名レベル Bucket Name
  BinaryDataLake: "binary-data-lake", // テナント単位のbinary Bucket Name
  DeveloperUsagePlanid: "developer-usage-planid", // デベロッパーポータルユーザ用の使用量プラン
};