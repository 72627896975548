import { memo, useCallback, useEffect, useState, FC } from "react";
import {
  Center,
  HStack,
  Spinner,
  Stack,
  Th,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import { useGroup } from "../../../hooks/useGroup";
import { GroupDetailModal } from "../../organisms/modal/GroupDetailModal";
import { Group } from "../../../types/api/group";
import { useUser } from "../../../hooks/useUser";
import { AddButton } from "../../atoms/button/AddButton";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { SearchInput } from "../../atoms/Input/SearchInput";
import { GroupRow } from "../../organisms/datarow/GroupRow";
import { TableContent } from "../../organisms/layout/TableContent";

export const Groups: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getGroups, groupAdd, groupEdit, groupDelete, loading, groups } =
    useGroup();
  const { getUsers, users } = useUser(onClose);

  const newGroup: Group = {
    tenant_id: "",
    group_id: "",
    title: "",
    description: "",
    users: [],
  };
  const [selectedGroup, setSelectedGroup] = useState<Group>(newGroup);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => getGroups(), [getGroups]);
  useEffect(() => getUsers(), [getUsers]);

  const onClickGroup = useCallback(
    (group_id: string) => {
      //      onSelectType({ typeName, types, dlType.onOpen });
      const targetGroup = groups.find((obj) => obj.group_id === group_id);
      if (targetGroup) {
        setSelectedGroup(targetGroup);
        onOpen();
      } else {
        setSelectedGroup(newGroup);
        onOpen();
      }
    },
    [groups, newGroup, onOpen]
  );

  return (
    <Stack maxW={"1200px"}>
      <PageDiscription>グループを選択してください。</PageDiscription>
      <HStack>
        <SearchInput
          placeholder={"グループ名 または ユーザーID"}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <AddButton onClick={() => onClickGroup("")} />
      </HStack>
      {loading ? (
        <Center h="50vh">
          <Spinner color="teal.200" />
        </Center>
      ) : (
        <>
          <TableContent
            headerRows={
              <Tr key="header">
                <Th>グループ名</Th>
                <Th>備考</Th>
                <Th>ユーザー数</Th>
              </Tr>
            }
            bodyRows={groups
              .filter((obj) => {
                return (
                  searchValue === "" ||
                  obj.title.indexOf(searchValue) >= 0 ||
                  obj.users.findIndex((userinfo) => {
                    return userinfo.userName.includes(searchValue);
                  }) >= 0
                );
              })
              .map((obj) => (
                <GroupRow
                  key={obj.group_id}
                  group_id={obj.group_id}
                  title={obj.title}
                  description={obj.description}
                  users_count={String(obj.users.length)}
                  onClick={onClickGroup}
                />
              ))}
          ></TableContent>
          {/* <Wrap px={{ base: 4, md: 10 }} py={{ base: 1, md: 2 }}>
            {groups
              .filter((obj) => {
                return (
                  searchValue === "" ||
                  obj.title.indexOf(searchValue) >= 0 ||
                  obj.users.findIndex((userinfo) => {
                    return userinfo.userName.includes(searchValue);
                  }) >= 0
                );
              })
              .map((obj) => (
                <WrapItem key={obj.group_id} mx="auto">
                  <GroupCard
                    group_id={obj.group_id}
                    title={obj.title}
                    description={obj.description}
                    users_count={"ユーザー数：" + String(obj.users.length)}
                    onClick={onClickGroup}
                  />
                </WrapItem>
              ))}
          </Wrap> */}
        </>
      )}
      <GroupDetailModal
        isOpen={isOpen}
        onInsert={groupAdd}
        onUpdate={groupEdit}
        onDelete={groupDelete}
        onClose={onClose}
        group={selectedGroup}
        tenantUsers={users}
      />
    </Stack>
  );
});
