import { memo, FC } from "react";
import { Box, Button, HStack, Spacer, Spinner, Stack, Td, Tr } from "@chakra-ui/react";
import { WorkflowStatusType,WorkflowStatistics } from "../../../types/api/workflow";
import { ExternalLinkIcon } from "@chakra-ui/icons";

type Props = {
    WorkflowRunId: string,
    StartedOn: string,
    CompletedOn: string,
    Status: WorkflowStatusType,
    Statistics: WorkflowStatistics,
    onClickGet?: (run_id: string) => void;
};

export const WorkflowRow: FC<Props> = memo((props) => {
    const { WorkflowRunId,StartedOn,CompletedOn,Status,Statistics,onClickGet } = props;
    return (
        <Tr key={WorkflowRunId} bg="gray.50" height={"50px"}>
            <Td fontSize={"sm"}>{new Date(StartedOn).toLocaleString()}</Td>
            <Td fontSize={"sm"}>{CompletedOn ? new Date(CompletedOn).toLocaleString() : ""}</Td>
            <Td fontSize={"sm"}>
                <HStack>
                    <Box>{Status}</Box>
                    <Box>
                        {Status === WorkflowStatusType.RUNNING ? (<Spinner size={"sm"} color="teal.200" />) : <></> }
                    </Box>                
                </HStack>
            </Td>
            <Td fontSize={"sm"}>
                {Status !== WorkflowStatusType.RUNNING && (
                    Statistics.TotalActions === Statistics.SucceededActions ? 
                        <Box>成功</Box> 
                        :
                        <HStack>
                            <Box color={"red"}>エラー</Box>
                            <Spacer/>
                            {onClickGet && (
                                <Button
                                    leftIcon={<ExternalLinkIcon />}
                                    onClick={() => onClickGet(WorkflowRunId)}
                                    variant="outline"
                                    colorScheme="red"
                                    size={"sm"}
                                    aria-label="詳細"
                                    ml="3"                    
                                >
                                    {"詳細"}
                                </Button>
                            )}
                        </HStack>    
                )}
            </Td>
        </Tr>
    );
});
