import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  Badge,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
} from "@chakra-ui/react";
import appconfig from "../../../config.json";
import { TenantInfo } from "../../../types/api/tenantTableTypes";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { useTenant } from "../../../hooks/useTenant";
import { TierPtn, TierType } from "../../../types/api/commonTypes";
import { Controller, useForm } from "react-hook-form";

type Props = {
  tenant: TenantInfo | undefined;
  isOpen: boolean;
  isAdmin?: boolean;
  onClose: () => void;
};

export const TenantDetailModal: FC<Props> = memo((props) => {
  const ver2:boolean = appconfig.IOT_MANAGEMENT_ENV.startsWith("2")
  const { isOpen, onClose, isAdmin = false, tenant } = props;
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<TenantInfo>({
    defaultValues: tenant,
  });
  const { tenantEdit, tenantDelete, loading } = useTenant();


  useEffect(() => {
    onModalReset();
  }, [tenant]);



  const onClickUpdate = (data:TenantInfo) => {
    if (tenant){
      tenant.accountName=data.accountName;
      tenant.companyName=data.companyName;
      tenant.email=data.email;
      tenant.tenantNamespace=data.tenantNamespace;
      tenant.tier = data.tier;
      tenant.binaryStorageCapacityLimit = data.binaryStorageCapacityLimit||0;
      tenantEdit(tenant);
    }
  };
  const handleOnError = (errors: any) => console.log(errors);

  const onClickDelete = () => {
    if (tenant?.tenant_id)
    tenantDelete(tenant?.tenant_id);
  };

  const onModalReset = () => {
    if(tenant){
      reset(tenant);
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={()=>{onModalReset();onClose()}}
      motionPreset="slideInBottom"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>テナント詳細</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{base:0,md:6}}>
          <Stack spacing={4}>
          <form>
            <FormControl>
              <FormLabel>テナントコード(市町村)</FormLabel>
              <Input
                id="tenant_id"
                placeholder="ID"
                value={tenant?.tenant_id}
              />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.accountName)}>
              <FormLabel>表示名</FormLabel>
              <Input
                id="accountName"
                placeholder="〇〇市"
                {...register("accountName", {
                  required: "必須入力です。",
                })}
              />
              <FormErrorMessage>
                {errors.accountName && errors.accountName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>会社名</FormLabel>
              <Input
                id="companyName"
                placeholder="companyName"
                {...register("companyName")}
              />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.email)}>
              <FormLabel>メールアドレス</FormLabel>
              <Input
                id="email"
                placeholder="email"
                {...register("email", {
                  required: false,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "正しいメールアドレスの形式で入力してください。",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.tenantNamespace)}>
              <FormLabel>ID設定</FormLabel>
              <Input
                id="tenantNamespace"
                placeholder="jp.xxxx.zzzz"
                {...register("tenantNamespace", {
                  required: "必須入力です。",
                  pattern: {
                    value: /^[A-Z0-9.]+$/i,
                    message: "ピリオド区切りの形式で入力してください。",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.tenantNamespace && errors.tenantNamespace.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.tier)}>
              <FormLabel>Plan</FormLabel>
              <Select id="tier" placeholder="plan" {...register("tier")}>
                <option value={TierPtn.no_personal}>Standard Tier</option>
                {ver2?(<option value={TierPtn.v2}>Professional Tier</option>):(<></>)}
              </Select>
              <FormErrorMessage>
                {errors.tier && errors.tier.message}
              </FormErrorMessage>
            </FormControl>
            {ver2?(<FormControl isDisabled={false}>
              <FormLabel>バイナリ：ストレージ制限[GB]</FormLabel>
              <Controller
                name="binaryStorageCapacityLimit"
                control={control}
                render={({ field: { ref, ...restField } }) => (
                  <NumberInput
                    {...restField}
                    precision={0}
                    step={1}
                    min={0}
                    max={1000}
                  >
                    <NumberInputField name={restField.name} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                )}
              />
            </FormControl>):(<></>)}
          </form>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <PrimaryButton onClick={handleSubmit(onClickUpdate, handleOnError)}>更新</PrimaryButton>
          {isAdmin?(<PrimaryButton onClick={onClickDelete}>削除</PrimaryButton>):(<></>)}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
