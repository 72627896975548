import { ChangeEvent, memo, useEffect, useState, FC } from "react";
import {
  Badge,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { ApiKey } from "../../../types/api/apikey";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";

type Props = {
  apiKey: ApiKey;
  isOpen: boolean;
  isAdmin?: boolean;
  onInsert: (name: string, description: string) => void;
  onUpdate: (apiKey: ApiKey) => void;
  onDelete: (id: string) => void;
  onEnable: (id: string) => void;
  onDisable: (id: string) => void;
  onClose: () => void;
  loading: boolean;
};

export const ApiKeyDetailModal: FC<Props> = memo((props) => {
  const { isOpen, onClose, apiKey, loading } = props;

  const isAddMode: boolean = apiKey.name ? false : true;

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [enabled, setEnabled] = useState<boolean>(true);

  useEffect(() => {
    onModalReset();
  }, [apiKey]);

  const onChangeId = (e: ChangeEvent<HTMLInputElement>) =>
    setId(e.target.value);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) =>
    setDescription(e.target.value);

  const onClickUpdate = () => {
    if (id) {
      apiKey.name = name;
      if (description) {
        if (description !== "") {
          apiKey.description = description;
        } else {
          delete apiKey.description;
        }
      } else {
        delete apiKey.description;
      }
      if (enabled) {
        apiKey.enabled = "true";
      } else {
        apiKey.enabled = "false";
      }

      props.onUpdate(apiKey);
      props.onClose();
    }
  };

  const onClickCreate = () => {
    props.onInsert(name, description);
    props.onClose();
  };

  const onClickDelete = () => {
    if (id) {
      if (window.confirm(`「${apiKey.name}（${apiKey.id}）」を削除しますか？`)) {
        props.onDelete(id);
        props.onClose();
      }
    }
  };

  const onModalReset = () => {
    if (apiKey) {
      setId(apiKey.id ?? "");
      setName(apiKey.name ?? "");
      setDescription(apiKey.description ?? "");
      if (typeof apiKey.enabled === 'boolean'){
        setEnabled(apiKey.enabled)
      }else {
        if (apiKey.enabled) {
          if (apiKey.enabled === "False") {
            setEnabled(false);
          } else {
            setEnabled(true);
          }
        } else {
          setEnabled(true);
        }
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onModalReset();
        onClose();
      }}
      motionPreset="slideInBottom"
      autoFocus={false}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent pb={2}>
        <ModalHeader>ApiKey詳細</ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={{ base: 0, md: 6 }}>
          <Tabs>
            <TabList>
              <Tab>詳細</Tab>
            </TabList>
              {loading ? (
                  <Center h="50vh">
                    <Spinner color="teal.200" />
                  </Center>
                ) : (
                <TabPanels>
                  <TabPanel>
                    <Stack spacing={4}>
                      <FormControl>
                        <FormLabel>
                          ID
                          <Badge colorScheme="gray" ml={2}>
                            変更不可
                          </Badge>
                        </FormLabel>
                        <Input
                          readOnly={true}
                          value={id}
                          placeholder="ID"
                          onChange={onChangeId}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>
                          名称
                          <Badge colorScheme="red" ml={2}>
                            必須
                          </Badge>
                        </FormLabel>
                        <Input
                          value={name}
                          placeholder="name"
                          onChange={onChangeName}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>
                          APIKEY
                          <Badge colorScheme="gray" ml={2}>
                            変更不可
                          </Badge>
                        </FormLabel>
                        <Input
                          readOnly={true}
                          value={apiKey.value}
                          placeholder="APIKEY"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>備考</FormLabel>
                        <Input
                          value={description}
                          placeholder="description"
                          onChange={onChangeDescription}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Enabled</FormLabel>
                        <Checkbox
                          isChecked={enabled}
                          onChange={(e) => setEnabled(e.target.checked)}
                        />
                      </FormControl>
                    </Stack>
                  </TabPanel>
                </TabPanels>
              )
            }
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <HStack>
            {isAddMode ? (
              <PrimaryButton disabled={loading || name === ""} onClick={onClickCreate}>
                追加
              </PrimaryButton>
            ) : (
              <>
                <PrimaryButton disabled={loading} onClick={onClickUpdate}>更新</PrimaryButton>
                <PrimaryButton disabled={loading} onClick={onClickDelete}>削除</PrimaryButton>
              </>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
