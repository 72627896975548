import { ChangeEvent, memo, useCallback, useEffect, useState, FC, useRef } from "react";
import {
    Button,
    Center,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Select,
    Spacer,
    Spinner,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Th,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";

import { useFileUpload } from "../../../hooks/useOpenSearchFileUpload";
import { useWorkflow } from "../../../hooks/useWorkflow";
import { useTenant } from "../../../hooks/useTenant";
import { DeviceInfo } from "../../../types/api/tenantTableTypes";
import { AddButton } from "../../atoms/button/AddButton";
import { PageDiscription } from "../../atoms/button/PageDiscription";
import { FileRow } from "../../organisms/datarow/FileRow";
import { TableContent } from "../../organisms/layout/TableContent";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { LinkIcon, RepeatIcon } from "@chakra-ui/icons";
import { useParameters } from "../../../hooks/useParameters";
import { WorkflowRow } from "../../organisms/datarow/WorkflowRow";
import { WorkflowStatusType } from "../../../types/api/workflow";
import { OpensearchErrorModal } from "../../organisms/modal/OpensearchErrorModal";

export const OpensearchUploads: FC = memo(() => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { loginUser, selectTenant } = useLoginUser();
    const { getSourceFileList,getDoneFileList, clearFileList, getFile, putFile, deleteFile, 
            sourceLoading,doneLoading,loading: fileLoading, uploading: uploadLoading, jsonContent,
            sourceFiles,doneFiles } = useFileUpload();
    const { runWorkflow, getWorkflowStatus, loading: workflowLoading, workflowStatus } = useWorkflow();
    const { tenantGet, tenant, loading: deviceLoading } = useTenant();
    const { getParameter, parameter, loading: paramLoading } = useParameters();

    const refFileInput = useRef<HTMLInputElement>(null);

    const [deviceName, setDeviceId] = useState<string>();
    const [csvDevices, setCsvDevices] = useState<DeviceInfo[]>();

    const [bucket, setBucket] = useState<string | undefined>(undefined);
    const [prefix, setPrefix] = useState<string | undefined>(undefined);
    const [workflow, setWorkflow] = useState<string | undefined>(undefined);

    const [workflowRunning, setWorkflowRunning] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(true);

    const didLogRef = useRef(false);
    useEffect(() => {
        if (didLogRef.current === false) {
            didLogRef.current = true;
            let tenant_id: string = "";
            if (selectTenant) {
                tenant_id = selectTenant;
            } else {
                if (loginUser) tenant_id = loginUser.tenant;
            }
            tenantGet(tenant_id);
        }
    }, []);

    // bucketとprefの設定があるDevice（=CSVアップロードのデバイス）のみの一覧を設定
    useEffect(() => {
        if (tenant) {
            setCsvDevices(
                tenant?.devices?.filter((device) => {
                    return device.sourceBucket && device.sourcePref
                })
            );
        }
    }, [tenant]);

    // deviceを選択した際の処理
    const onChangeDeviceId = (e: ChangeEvent<HTMLSelectElement>) => {
        setWorkflowRunning(true);
        const selectDevice = csvDevices?.find((device) => device.DeviceName === e.target.value);
        setWorkflow(undefined);
        if (selectDevice) {
            setBucket(selectDevice.sourceBucket);
            setPrefix(selectDevice.sourcePref);
            getParameter(selectDevice.covWorkFlow ?? "");
        } else {
            setBucket(undefined);
            setPrefix(undefined);
            setWorkflow(undefined);
            clearFileList();
        }
    };

    useEffect(() => {
        if (bucket && prefix) getSourceFileList(bucket, prefix);
    }, [bucket, prefix, getSourceFileList]);
    useEffect(() => {
        if (bucket && prefix) getDoneFileList(bucket, prefix);
    }, [bucket, prefix, getDoneFileList]);

    useEffect(() => {
        setWorkflow(parameter);
    }, [parameter]);
    useEffect(() => {
        getWorkflowStatus(workflow);
    }, [workflow]);

    useEffect(() => {
        if(workflowStatus.find((state)=>{return state.Status === WorkflowStatusType.RUNNING})){
            setWorkflowRunning(true);
        }else{
            setWorkflowRunning(false);
        }        
    }, [workflowStatus]);

    const [csvfile, setCsvFile] = useState<File>();
    const getCsvFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        const file: File = e.target.files[0];
        setCsvFile(file);
    };
    const onClickFileUpload = useCallback(
        (bucket: string | undefined, prefix: string | undefined, file: File | undefined) => {
            if (bucket && prefix && file) {
                putFile(bucket, prefix, file);
                if(refFileInput?.current) refFileInput.current.value = "";
                setCsvFile(undefined);
            }
        },
        []);

    const onClickFileDownload = useCallback((bucket: string | undefined, key: string) => {
        if (bucket && key) {
            getFile(bucket, key);
        }
    }, []);
    const onClickErrorFileJson = useCallback((run_id: string) => {
        if (bucket && prefix && run_id) {
            getFile(bucket, prefix + "/result/" + run_id + "/result.json",true);
            onOpen();   // モーダルを開く
        }
    }, [bucket,prefix]);
    
    const onClickFileDelete = useCallback((bucket: string | undefined, prefix: string | undefined, key: string) => {
        if (bucket && prefix && key) {
            deleteFile(bucket, prefix, key);
        }
    }, []);
    const onClickDoneFileDelete = useCallback((bucket: string | undefined, prefix: string | undefined, key: string) => {
        if (bucket && prefix && key) {
            if (window.confirm(`処理済みファイルを削除しますか？（取込済みのデータは削除されません。）`)) {
                deleteFile(bucket, prefix, key);
            }
        }
    }, []);
    const onClickWorkflowRun = useCallback((workflow: string) => {
        if (workflow) {
            if (window.confirm(`アップロードしたファイルの取込処理を実行しますか？（元には戻せません。）`)) {
                runWorkflow(workflow);
            }
        }
    }, []);

    // 更新ボタン（ファイル一覧とworkflowの処理状態を取得しなおす）
    const onClickReload = useCallback(() => {
        if (bucket && prefix) getSourceFileList(bucket, prefix);
        if (bucket && prefix) getDoneFileList(bucket, prefix);
        getWorkflowStatus(workflow);
    }, [bucket, prefix, workflow, getSourceFileList, getDoneFileList, getWorkflowStatus]);

    useEffect(() => {
        setDisabled(deviceLoading || fileLoading || uploadLoading || workflowLoading || workflowRunning || paramLoading || sourceLoading || doneLoading);
    }, [deviceLoading , fileLoading , uploadLoading , workflowLoading , workflowRunning, paramLoading, sourceLoading, doneLoading]);
    
    return (
        <Stack maxW={"1200px"}>
            <PageDiscription>
                ドライバを選択し、CSVファイルのアップロードとデータ取込処理を実行してください。
            </PageDiscription>
            <FormControl>
                <HStack>
                    <FormLabel whiteSpace={"nowrap"}>ドライバの種類</FormLabel>
                    {deviceLoading ? (
                        <Spinner key="deviceSpinner" color="teal.200" />
                    ) : (
                        <Select value={deviceName} onChange={onChangeDeviceId} bg="gray.50" disabled={uploadLoading}>
                            <option value={""}></option>
                            {csvDevices?.map((device) => (
                                <option key={device.DeviceName} value={device.DeviceName}>
                                    {device.DeviceName}
                                </option>
                            ))}
                        </Select>
                    )}
                </HStack>
            </FormControl>
            <Tabs>
                <TabList>
                    <Tab>ファイルアップロード</Tab>
                    <Tab>データ取込処理</Tab>
                    <Tab>処理済みファイル</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <HStack my="2" h="10">
                            <Input
                                id="file"
                                ref={refFileInput}
                                type="file"
                                accept=".csv"
                                onChange={getCsvFile}
                                disabled={disabled}
                                sx={{
                                    "::file-selector-button,::-webkit-file-upload-button": {
                                        backgroundColor: "rgb(209, 213, 219)",
                                        color: "rgb(31, 41, 55)",
                                        border: "none",
                                        cursor: "pointer",
                                        br: "1px solid rgb(191, 194, 199)",
                                        padding: "0.25rem 1rem",
                                        mr: "1rem",
                                        height: "100%",
                                    },
                                }}
                                bg={"gray.50"}
                                size={{ sm: "sm", md: "md" }}
                            />
                            <AddButton
                                text="アップロード"
                                loading={uploadLoading}
                                disabled={disabled}
                                onClick={() => onClickFileUpload(bucket, prefix, csvfile)}
                            />
                            <Flex pr="3">
                                <Button
                                    leftIcon={<RepeatIcon />}
                                    colorScheme="teal"
                                    variant="solid"
                                    onClick={onClickReload}
                                    size={{ base: "sm", md: "md" }}
                                >
                                    {"更新"}
                                </Button>
                            </Flex>
                        </HStack>
                        {fileLoading || sourceLoading || doneLoading ? (
                            <Center h="50vh">
                                <Spinner color="teal.200" />
                            </Center>
                        ) : (
                            <>
                                <TableContent
                                    heightOffset="-100px"
                                    headerRows={
                                        <Tr key="header">
                                            <Th w="100%">ファイル名</Th>
                                            <Th>アップロード日時</Th>
                                            <Th>ファイルサイズ</Th>
                                            <Th textAlign={"center"}>削除</Th>
                                        </Tr>
                                    }
                                    bodyRows={sourceFiles.map((obj) => (
                                        <FileRow
                                            key={obj.Key}
                                            bucket={bucket ?? ""}
                                            prefix={prefix ?? ""}
                                            filekey={obj.Key}
                                            lastModified={obj.LastModified}
                                            size={obj.Size}
                                            onClickDelete={onClickFileDelete}
                                            disabled={disabled}
                                            />
                                    ))}
                                ></TableContent>
                            </>
                        )}
                    </TabPanel>
                    <TabPanel>
                        <HStack my="2" h="10" alignItems={"flex-end"}>
                            <FormLabel>処理実行履歴（最新10件）</FormLabel>
                            <Spacer />
                            <Flex pr="5">
                                <Button
                                    colorScheme="red"
                                    variant="solid"
                                    onClick={() => onClickWorkflowRun(workflow ?? "")}
                                    size={{ base: "sm", md: "md" }}
                                    disabled={disabled}
                                    >
                                    {"取込処理実行"}
                                </Button>
                            </Flex>
                            <Flex pr="3">
                                <Button
                                    leftIcon={<RepeatIcon />}
                                    colorScheme="teal"
                                    variant="solid"
                                    onClick={onClickReload}
                                    size={{ base: "sm", md: "md" }}
                                >
                                    {"更新"}
                                </Button>
                            </Flex>
                        </HStack>
                        {workflowLoading ? (
                            <Center h="50vh">
                                <Spinner color="teal.200" />
                            </Center>
                        ) : (
                            <>
                                <TableContent
                                    heightOffset="-100px"
                                    headerRows={
                                        <Tr key="header">
                                            <Th>処理開始日時</Th>
                                            <Th>処理完了日時</Th>
                                            <Th>状態</Th>
                                            <Th w="100%">結果</Th>
                                        </Tr>
                                    }
                                    bodyRows={workflowStatus.map((obj) => (
                                        <WorkflowRow
                                            key={obj.WorkflowRunId}
                                            WorkflowRunId={obj.WorkflowRunId}
                                            StartedOn={obj.StartedOn}
                                            CompletedOn={obj.CompletedOn}
                                            Status={obj.Status}
                                            Statistics={obj.Statistics}
                                            onClickGet={onClickErrorFileJson}
                                        />
                                    ))}
                                ></TableContent>
                            </>
                        )}
                    </TabPanel>
                    <TabPanel>
                        <HStack my="2" h="10">
                            <Flex>
                            </Flex>
                            <Spacer/>
                            <Flex pr="3">
                                <Button
                                    leftIcon={<RepeatIcon />}
                                    colorScheme="teal"
                                    variant="solid"
                                    onClick={onClickReload}
                                    size={{ base: "sm", md: "md" }}
                                >
                                    {"更新"}
                                </Button>
                            </Flex>
                        </HStack>
                        {fileLoading || sourceLoading || doneLoading ? (
                            <Center h="50vh">
                                <Spinner color="teal.200" />
                            </Center>
                        ) : (
                            <>
                                <TableContent
                                    heightOffset="-100px"
                                    headerRows={
                                        <Tr key="header">
                                            <Th w="100%">ファイル名</Th>
                                            <Th>取込処理日時</Th>
                                            <Th>ファイルサイズ</Th>
                                            <Th textAlign={"center"}>削除</Th>
                                        </Tr>
                                    }
                                    bodyRows={doneFiles.map((obj) => (
                                        <FileRow
                                            key={obj.Key}
                                            bucket={bucket ?? ""}
                                            prefix={prefix ?? ""}
                                            filekey={obj.Key}
                                            lastModified={obj.LastModified}
                                            size={obj.Size}
                                            onClickGet={onClickFileDownload}
                                            onClickDelete={onClickDoneFileDelete}
                                            disabled={disabled}
                                            />
                                    ))}
                                ></TableContent>
                            </>
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <OpensearchErrorModal
                ErrorFiles={jsonContent}
                isOpen={isOpen}
                onClose={onClose}
            />
        </Stack>
    );
});
