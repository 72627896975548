import { memo, FC } from "react";
import { Td, Tr } from "@chakra-ui/react";

type Props = {
  timestamp: string;
  sum: number | undefined;
  average: number | undefined;
  maximum: number | undefined;
  minimum: number | undefined;
  unit: string;
};

export const DatapointRow: FC<Props> = memo((props) => {
  const { timestamp, sum, average, maximum, minimum, unit } = props;
  return (
    <Tr key={timestamp} bg="gray.50">
      <Td>{new Date(timestamp).toLocaleString()}</Td>
      {(sum != undefined) ? (
        <Td>
          {unit === "Bytes" ? Math.ceil((sum * 10) / (1024 * 1024)) / 10 : sum}
        </Td>
      ) : (
        <></>
      )}
      {(average != undefined) ? (
        <Td>
          {unit === "Bytes"
            ? Math.ceil((average * 10) / (1024 * 1024)) / 10
            : average}
        </Td>
      ) : (
        <></>
      )}
      {(maximum != undefined) ? (
        <Td>
          {unit === "Bytes"
            ? Math.ceil((maximum * 10) / (1024 * 1024)) / 10
            : maximum}
        </Td>
      ) : (
        <></>
      )}
      {(minimum != undefined) ? (
        <Td>
          {unit === "Bytes"
            ? Math.ceil((minimum * 10) / (1024 * 1024)) / 10
            : minimum}
        </Td>
      ) : (
        <></>
      )}
      <Td>{unit == "Bytes" ? "GB" : unit}</Td>
    </Tr>
  );
});
